<template lang="html">
  <header id="header" v-bind:class="{ 'transparent-header' : transparentHeader }">
    <div class="header-content">
      <b-navbar toggleable="lg" type="dark" variant="info">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo-white.svg" alt="Koalitio">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fal fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/">Start</b-nav-item>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>Solutions</span>
                </template>
                <b-dropdown-item to="/advanced-computing">ADVANCED COMPUTING</b-dropdown-item>
                <b-dropdown-item to="/analytics-al-automation">ANALYTICS, AL & AUTOMATION</b-dropdown-item>
                <b-dropdown-item to="/cloud-solutions">CLOUD SOLUTIONS</b-dropdown-item>
                <b-dropdown-item to="/customer-experience">CUSTOMER EXPERIENCE</b-dropdown-item>
                <b-dropdown-item to="/cybersecurity">CYBERSECURITY</b-dropdown-item>
                <b-dropdown-item to="/digital-consulting">DIGITAL CONSULTING</b-dropdown-item>
                <b-dropdown-item to="/modern-applications-platforms">MODERN APPLICATIONS & PLATFORMS</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item" to="/portfolio">Portfolio</b-nav-item>
              <b-nav-item class="simple-item" to="/about-us">About us</b-nav-item>
              <b-nav-item class="simple-item" to="/contact-us">Contact us</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      transparentHeader: false,
    }
  },

  methods: {
    verifyPage() {
      if(this.$route.name == 'homePage' || this.$route.name == 'aboutUsPage' || this.$route.name == 'portfolioPage' || this.$route.name == 'soluPage') {
        this.transparentHeader = true;
      }else {
        this.transparentHeader = false;
      }
    },
  },

  watch:{
    '$route' (to, from){
      this.verifyPage();
    }
  },

  mounted() {
    this.verifyPage();
  }
}
</script>
