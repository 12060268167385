<template lang="html">
  <div id="solution-v2-page">

    <section class="padding-top-h main-section solution-5" data-aos="fade">
      <div class="bg" data-aos="fade-right" data-aos-delay="50"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-empty"></div>

          <div class="col-lg-6 col-text">
            <h1 class="mb-2 title" data-aos="fade-left" data-aos-delay="100">Cybersecurity</h1>

            <!-- <h3 class="mt-2 subtitle" data-aos="fade-left" data-aos-delay="200">This is a small subtitle</h3> -->

            <p class="p-check" data-aos="fade-left" data-aos-delay="150">
              <i class="far fa-check"></i> Advanced Detection and Response
            </p>
            <p class="p-check" data-aos="fade-left" data-aos-delay="200">
              <i class="far fa-check"></i> Data Protection and Governance
            </p>
            <p class="p-check" data-aos="fade-left" data-aos-delay="250">
              <i class="far fa-check"></i> Digital Workplace Security
            </p>
            <p class="p-check" data-aos="fade-left" data-aos-delay="300">
              <i class="far fa-check"></i> Hybrid Cloud Security loT and OT Security
            </p>
            <p class="p-check" data-aos="fade-left" data-aos-delay="350">
              <i class="far fa-check"></i> Trusted Digital Identities
            </p>
            <p class="p-check" data-aos="fade-left" data-aos-delay="400">
              <i class="far fa-check"></i> Cybersecurity Products
            </p>
            <p class="p-check" data-aos="fade-left" data-aos-delay="450">
              <i class="far fa-check"></i> Cybersecurity solutions
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
