<template lang="html">
  <div id="footer">
		<div class="box-newsletter">
			<div class="container" data-aos="fade">
				<h3 class="title">SUSCRIBE TO OUR NEWSLETTER</h3>

				<b-form @submit="onSubmit">
					<b-form-input
						v-model="form.email"
						type="email"
						placeholder="WRITE YOUR EMAIL"
						required
					></b-form-input>

					<b-button type="submit" class="btn-send">SUSCRIBE</b-button>
				</b-form>
			</div>
		</div>

		<footer class="box-footer">
			<div class="container oversized-container" data-aos="fade">
				<div class="row">
					<div class="col-md-5 col-logo">
						<router-link to="/">
							<img src="public/images/logo-white.svg" alt="Koalitio">
						</router-link>
					</div>

					<div class="col-md-7 col-footer cf-2">
						<div class="box-networks">
							<a class="t-150 btn-network" href="#">
								<i class="fab fa-instagram"></i>
							</a>
							<a class="t-150 btn-network" href="#">
								<i class="fab fa-facebook-f"></i>
							</a>
							<a class="t-150 btn-network" href="#">
								<i class="fab fa-twitter"></i>
							</a>
						</div>

						<p class="mb-1">
							(34) 5645 4564
						</p>
						<p class="mb-1">
							<a href="mailto:hello@advent.ca">HELLO@ADVENT.CA</a>
						</p>
						<p>
							MONT-ROYAL AVE. E 1233
						</p>
					</div>
				</div>
			</div>
		</footer>
  </div>
</template>

<script>
export default {
	data() {
		return {
			form: {
				email: null,
			}
		}
	},

	methods: {
    onSubmit(event) {
      event.preventDefault();
    },
	}
}
</script>
