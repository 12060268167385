<template lang="html">
  <div id="contact-page">

    <section class="container form-section" data-aos="fade-in">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="box-form">
            <h1 class="main-title" data-aos="fade-up">CONTACT US!</h1>

            <div class="mt-2 box-contact-info" data-aos="fade-up" data-aos-delay="50">
              <h5 class="title">Officies in:</h5>
              <hr class="mt-1" />
              <div class="row">
                <div class="col-lg-4 col-info">
                  <h6>Canada</h6>
                  <p>
                    (Montréal & Toronto)
                  </p>
                </div>

                <div class="col-lg-4 col-info">
                  <h6>USA</h6>
                  <p>
                    (Houston & Los Angeles)
                  </p>
                </div>

                <div class="col-lg-4 col-info">
                  <h6>Mexico</h6>
                  <p>
                    (Guadalajara)
                  </p>
                </div>

                <div class="col-lg-4 col-info">
                  <h6>Spain</h6>
                  <p>
                    (Málaga)
                  </p>
                </div>

                <div class="col-lg-4 col-info">
                  <h6>France</h6>
                  <p>
                    (Avignon)
                  </p>
                </div>
              </div>

              <h5 class="mt-4 title">Reach us:</h5>
              <hr class="mt-1" />
              <div class="row">
                <div class="col-lg-4 col-info">
                  <h6>North-America</h6>
                  <p>
                    1 (877)-906-2424
                  </p>
                </div>

                <div class="col-lg-4 col-info">
                  <h6>Montreal</h6>
                  <p>
                    +1(514) 906-2425
                  </p>
                </div>

                <div class="col-lg-4 col-info">
                  <h6>Europe</h6>
                  <p>
                    +0 (34)951 231 510
                  </p>
                </div>
              </div>
            </div>

            <b-form @submit="onSubmit">
              <b-form-group data-aos="fade-up" data-aos-delay="100">
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder="FULL NAME"
                  required
                ></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-md-6 pr-md-2" data-aos="fade-up" data-aos-delay="150">
                  <b-form-group>
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder="EMAIL"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6 pl-md-2">
                  <b-form-group data-aos="fade-up" data-aos-delay="200">
                    <b-form-input
                      v-model="form.phone"
                      type="text"
                      placeholder="PHONE NUMBER"
                      minlength="10"
                      maxlength="10"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12">
                  <b-form-group data-aos="fade-up" data-aos-delay="250">
                    <b-form-input
                      v-model="form.subject"
                      type="text"
                      placeholder="SUBJECT"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12">
                  <b-form-group data-aos="fade-up" data-aos-delay="300" data-aos-offset="-300">
                    <b-form-textarea
                      v-model="form.msg"
                      placeholder="MESSAGE"
                      rows="6"
                      max-rows="6"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="col-12 text-center" data-aos="fade-up" data-aos-delay="350" data-aos-offset="-300">
                  <b-button type="submit" class="t-400 btn-send">SEND</b-button>
                </div>
              </div>

            </b-form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        subject: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    }
  },
}
</script>
