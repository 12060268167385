<template lang="html">
  <div id="home-page">

    <section class="padding-top-h main-section" data-aos="fade">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="100">
            <router-link class="circle" to="/advanced-computing">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-2.svg" alt="ADVANCED COMPUTING">
                  </p>
                  <h6>ADVANCED COMPUTING</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="150">
            <router-link class="circle" to="/analytics-al-automation">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-3.svg" alt="ANALYTICS, AL & AUTOMATION">
                  </p>
                  <h6>ANALYTICS, AL & AUTOMATION</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="50">
            <router-link class="circle" to="/cloud-solutions">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-1.svg" alt="CLOUD SOLUTIONS">
                  </p>
                  <h6>CLOUD SOLUTIONS</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="200">
            <router-link class="circle" to="/customer-experience">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-4.svg" alt="CUSTOMER EXPERIENCE">
                  </p>
                  <h6>CUSTOMER EXPERIENCE</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="350">
            <router-link class="circle" to="/cybersecurity">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-7.svg" alt="CYBERSECURITY">
                  </p>
                  <h6>CYBERSECURITY</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="250">
            <router-link class="circle" to="/digital-consulting">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-5.svg" alt="DIGITAL CONSULTING">
                  </p>
                  <h6>DIGITAL CONSULTING</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-sm-6 col-lg-3 col-opt" data-aos="fade-down" data-aos-delay="300">
            <router-link class="circle" to="/modern-applications-platforms">
              <div class="circle-content">
                <div class="inside">
                  <p>
                    <img src="public/images/pages/home/icon-6.svg" alt="MODERN APPLICATIONS & PLATFORMS">
                  </p>
                  <h6>MODERN APPLICATIONS & PLATFORMS</h6>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
