<template lang="html">
  <div id="solution-v2-page">

    <section class="padding-top-h main-section solution-6" data-aos="fade">
      <div class="bg" data-aos="fade-right" data-aos-delay="50"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-empty"></div>

          <div class="col-lg-6 col-text">
            <h1 class="mb-2 title" data-aos="fade-left" data-aos-delay="100">Digital consulting</h1>
            <h4 class="mt-2 " data-aos="fade-left" data-aos-delay="200">(We assist CIOs in the acomplishment of their vital systems needs & vision)</h4>

            <p class="mt-3 p-lg" data-aos="fade-left" data-aos-delay="300">
              Digital Transformation ConsultingInternet of Things (IoT)Delivering smart business services that harness the value of your loT data.Infrastructure & Foundation ServicesDesigning, building and managing digital infrastructures
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
