import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', name: 'homePage', component: require(page+'home.vue').default },
      { path: '/portfolio', name: 'portfolioPage', component: require(page+'portfolio/index.vue').default, meta:{title:"Portfolio"} },
	    { path: '/about-us', name: 'aboutUsPage', component: require(page+'about-us/index.vue').default, meta:{title:"About us"} },
      { path: '/contact-us', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},

      // { path: '/solutions', name: 'soluPage', component: require(page+'solutions/index.vue').default, meta:{title:"Solutions"}},
      { path: '/advanced-computing', name: 'soluPage', component: require(page+'solutions/advanced-computing.vue').default, meta:{title:"Advanced computing"}},
      { path: '/analytics-al-automation', name: 'soluPage', component: require(page+'solutions/analytics-al-automation.vue').default, meta:{title:"Analytics, AL & Automation"}},
      { path: '/cloud-solutions', name: 'soluPage', component: require(page+'solutions/cloud-solutions.vue').default, meta:{title:"Cloud solutions"}},
      { path: '/customer-experience', name: 'soluPage', component: require(page+'solutions/customer-experience.vue').default, meta:{title:"Customer experience"}},
      { path: '/cybersecurity', name: 'soluPage', component: require(page+'solutions/cybersecurity.vue').default, meta:{title:"Cybersecurity"}},
      { path: '/digital-consulting', name: 'soluPage', component: require(page+'solutions/digital-consulting.vue').default, meta:{title:"Digital consulting"}},
      { path: '/modern-applications-platforms', name: 'soluPage', component: require(page+'solutions/modern-applications-platforms.vue').default, meta:{title:"Modern applications & platforms"}},
      // { path: '/digital-consulting', name: 'soluPage', component: require(page+'solutions/digital-consulting.vue').default, meta:{title:"Digital consulting"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Koalitio", filter: (title)=>{ return title+" - Koalitio"; } }
);

// export {routes};
export default MyRouter;
