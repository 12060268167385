<template lang="html">
  <div id="portfolio-page">

    <section class="placed-backg padding-top-h banner-section" data-aos="fade">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12">
            <div class="box-text">
              <h1 data-aos="fade-right">Portfolio</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content-section" data-aos="fade-up" data-aos-delay="100">
      <div class="container oversized-container">
        <div class="box-content">
          <h5 class="subtitle">SUBTÍTULO</h5>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <div class="box-projects">
            <div class="col-md-6 col-lg-4 col-xl-3 col-project" data-aos="fade-up" :data-aos-delay="100 * pInx" v-for="(p, pInx) in 8" :key="'pInx-'+pInx">
              <div class="box" @click="openModal()">
                <div class="hover"></div>

                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/portfolio/project-1.jpg)' }"></div>

                <div class="box-text">
                  <div class="txt">Título de ejemplo</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal modal-class="modal-project" ref="modal-project" hide-footer centered size="xl" title="">
      <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/portfolio/project-1-lg.jpg)' }"></div>

      <div class="box-descr">
        <h5 class="title">Project name</h5>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  methods: {
    openModal() {
      this.$refs['modal-project'].show()
    }
  },

  mounted() {

  },
}
</script>
